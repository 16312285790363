import {SET_LOADING_STATE} from '../actions/appActtions';
import {SET_BACKGROUND_LOADING_STATE} from '../actions/appActtions';
import {FORCE_REFRESH} from '../actions/appActtions';
import {ACCEPT_COOKIES} from '../actions/appActtions';
import {SET_GLOBAL_SETTINGS} from '../actions/appActtions';

const initState = {
  loading: false,
  loading_in_background: false,
  force_refresh: false,
  cookies_accepted: false,
  global_settings: {}
};

const appReducer = (state = initState, action) => {
  if (action.type === ACCEPT_COOKIES) {
    return {
      ...state,
      cookies_accepted: true
    }
  }
  if (action.type === SET_LOADING_STATE) {
    return {
      ...state,
      loading: action.payload
    }
  }
  if (action.type === SET_BACKGROUND_LOADING_STATE) {
    return {
      ...state,
      loading_in_background: action.payload
    }
  }
  if (action.type === FORCE_REFRESH) {
    const current = state.force_refresh;
    return {
      ...state,
      force_refresh: !current
    }
  }
  if (action.type === SET_GLOBAL_SETTINGS) {
    const current = state.global_settings;
    const payload = action.payload;
    return {
      ...state,
      global_settings: payload
    }
  }
  
  return state;
}

export default appReducer;