export const ADD_CATEGORY = "ADD_CATEGORY";
export const REPLACE_CATEGORY = "REPLACE_CATEGORY";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const SELECT_ALL_CATEGORIES = "SELECT_ALL_CATEGORIES";
export const DESELECT_ALL_CATEGORIES = "DESELECT_ALL_CATEGORIES";
export const ADD_ATTRIBUTE = "ADD_ATTRIBUTE";
export const REMOVE_ATTRIBUTE = "REMOVE_ATTRIBUTE";
export const ADD_TAG = "ADD_TAG";
export const REMOVE_TAG = "REMOVE_TAG";
export const CLEAR_TAGS = "CLEAR_TAGS";
export const SET_PAGE = "SET_PAGE";
export const SET_RESULTS_PER_PAGE = "SET_RESULTS_PER_PAGE";
export const SET_SEARCH = "SET_SEARCH";
export const SET_FILTER_BY = "SET_FILTER_BY";
export const SET_SORT_BY = "SET_SORT_BY";
export const SET_SORT_TYPE = "SET_SORT_TYPE";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SWITCH_FAVOURITES = "SWITCH_FAVOURITES";

const addCategorySuccess = category => ({
    type: ADD_CATEGORY,
    payload: parseInt(category)
});

const removeCategorySuccess = category => ({
    type: REMOVE_CATEGORY,
    payload: parseInt(category)
});

const replaceCategorySuccess = category => ({
    type: REPLACE_CATEGORY,
    payload: parseInt(category)
});

const setSearchSuccess = search => ({
    type: SET_SEARCH,
    payload: search
});

const setPageSuccess = page => ({
    type: SET_PAGE,
    payload: parseInt(page)
});

const setPerPageSuccess = perpage => ({
    type: SET_RESULTS_PER_PAGE,
    payload: parseInt(perpage)
});

const setFilterBySuccess = filterby => ({
    type: SET_FILTER_BY,
    payload: filterby
});

const setSortBySuccess = sortby => ({
    type: SET_SORT_BY,
    payload: sortby
});

const selectAllCategoriesSuccess = (categories) => ({
    type: SELECT_ALL_CATEGORIES,
    payload: categories
});

const deselectAllCategoriesSuccess = () => ({
    type: DESELECT_ALL_CATEGORIES
});

const setTotalPagesSuccess = (totalPages) => ({
    type: SET_TOTAL_PAGES,
    payload: totalPages
});

// TAGS
const addTagSuccess = (tag) => ({
    type: ADD_TAG,
    payload: parseInt(tag)
});

const removeTagSuccess = (tag) => ({
    type: REMOVE_TAG,
    payload: parseInt(tag)
});

const clearTagsSuccess = () => ({
    type: CLEAR_TAGS,
    payload: null
});

export const switchFavourites = () => {
    return dispatch => {
        dispatch({
            type: SWITCH_FAVOURITES,
            payload: null
        });
    };
};

export const replaceCategory = category => {
    return dispatch => {
        dispatch(replaceCategorySuccess(category));
    };
};

export const addCategory = category => {
    return dispatch => {
        dispatch(addCategorySuccess(category));
    };
};

export const removeCategory = category => {
    return dispatch => {
        dispatch(removeCategorySuccess(category));
    };
};

export const setSearch = search => {
    return dispatch => {
        dispatch(setSearchSuccess(search));
    };
};

export const setPage = search => {
    return dispatch => {
        dispatch(setPageSuccess(search));
    };
};

export const setPerPage = search => {
    return dispatch => {
        dispatch(setPerPageSuccess(search));
    };
};

export const setFilterBy = search => {
    return dispatch => {
        dispatch(setFilterBySuccess(search));
    };
};

export const setSortBy = sortby => {
    return dispatch => {
        dispatch(setSortBySuccess(sortby));
    };
};

export const selectAllCategories = categories => {
    return dispatch => {
        dispatch(selectAllCategoriesSuccess(categories));
    };
};

export const deselectAllCategories = () => {
    return dispatch => {
        dispatch(deselectAllCategoriesSuccess());
    };
};

export const setTotalPages = totalPages => {
    return dispatch => {
        dispatch(setTotalPagesSuccess(totalPages));
    };
};

export const addTag = tag => {
    return dispatch => {
        dispatch(addTagSuccess(tag));
    };
};

export const removeTag = tag => {
    return dispatch => {
        dispatch(removeTagSuccess(tag));
    };
};

export const clearTags = () => {
    return dispatch => {
        dispatch(clearTagsSuccess());
    };
};
