export const SAVE_CATEGORIES = "SAVE_CATEGORIES";

const saveCategoriesSuccess = categories => ({
    type: SAVE_CATEGORIES,
    payload: categories
});

export const saveCategories = categories => {
    return dispatch => {
        dispatch(saveCategoriesSuccess(categories));
    };
};
