import React, { Fragment, useState } from "react";
import multilanguage from "redux-multilanguage/hoc/multilanguage";
import {forEach} from "react-bootstrap/cjs/ElementChildren";

const ErrorBox = ({ strings, validationResult }) => {
    const styles = {
        validatorBox: {
            padding: 5,
            backgroundColor: "#d93629",
            borderRadius: 5,
            border: "1px #911a10 solid",
            marginBottom: 10
        },
        validationHeader: {
            color: "#fff",
            fontWeight: 600
        },
        validationList: {
            color: "#fff"
        },
        validationListItem: {
            fontSize: '0.8em',
            lineHeight: '1.2em'
        }
    }

    if (validationResult.length > 0) {
        return (
            <div style={styles.validatorBox}>
                <h5 style={styles.validationHeader}>{strings['validation_errors']['header']}</h5>
                <ul style={styles.validationList}>
                    {validationResult.map((row, index) => {
                        return (
                            <li style={styles.validationListItem} key={index}>{strings['validation_errors'][row.error].replace('FIELD_NAME', strings['fields'][row.field]).replace('VALIDATION_VALUE', row.validation_value).replace('FIELD_COMPARE', strings['fields'][row.field_compare])}</li>
                        )
                    })}
                </ul>
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}

export const Validate = ( rules, fields ) => {
    let validationResult = [];
    Object.keys(fields).forEach(key => {
        const value = fields[key];
        const ruleSetRaw = rules[key];
        if (ruleSetRaw) {
            const ruleSet = ruleSetRaw.split('|');
            ruleSet.forEach(rule => {
               const parts = rule.split(':');
               if (parts.length === 1) {
                   switch (rule) {
                       case 'required':
                           if (value?.length === 0 || value === undefined || value === null) {
                               validationResult.push({
                                   'field': key,
                                   'error': 'required'
                               })
                           }
                           break;
                       case 'email':
                           if (value.indexOf('.') === -1 || value.indexOf('@') === -1 || value.length < 3) {
                               validationResult.push({
                                   'field': key,
                                   'error': 'email'
                               })
                           }
                           break;
                       case 'accept':
                           if (value !== true) {
                               validationResult.push({
                                   'field': key,
                                   'error': 'accept'
                               })
                           }
                           break;
                   }
               } else if (parts.length === 2) {
                   switch (parts[0]) {
                       case 'min':
                           if (value?.length < parts[1] || value === undefined || value === null) {
                               validationResult.push({
                                   'field': key,
                                   'error': 'minimum_length',
                                   'validation_value': parts[1]
                               })
                           }
                           break;
                       case 'max':
                           if (value?.length > parts[1] || value === undefined || value === null) {
                               validationResult.push({
                                   'field': key,
                                   'error': 'maximum_length',
                                   'validation_value': parts[1]
                               })
                           }
                           break;
                       case 'contains':
                           switch (parts[1]) {
                               case 'lowercase':
                                   if (!/[a-z]/.test(value)) {
                                       validationResult.push({
                                           'field': key,
                                           'error': 'contains_lowercase',
                                       })
                                   }
                                   break;
                               case 'uppercase':
                                   if (!/[A-Z]/.test(value)) {
                                       validationResult.push({
                                           'field': key,
                                           'error': 'contains_uppercase',
                                       })
                                   }
                                   break;
                               case 'number':
                                   if (!/[0-9]/.test(value)) {
                                       validationResult.push({
                                           'field': key,
                                           'error': 'contains_number',
                                       })
                                   }
                                   break;
                               case 'special':
                                   if (!/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(value)) {
                                       validationResult.push({
                                           'field': key,
                                           'error': 'contains_special',
                                       })
                                   }
                                   break;
                           }
                           break;
                   }
               }
            });
        }
    })
    return validationResult;
}

export const ValidationErrorBox = multilanguage(ErrorBox)


