import Cookies from 'universal-cookie';
import api from '../api/api';

const cookies = new Cookies();

export default class AuthService {
    static async attemptLogin(credentials) {
        const response = await api.login(credentials);
        if (await response.access_token) {
            let date = new Date();
            if (credentials.remember) {
                date = new Date(date.setMonth(date.getMonth()+1));
            } else {
                date = new Date(date.setMonth(date.getDate()+1));
            }
            cookies.set('token', response.access_token);
            cookies.set('token_expiry', date.getTime());
            return true;
        }
        else {
            return await response;
        }
    }

    static checkToken() {
        const token = cookies.get('token');
        if (token) {
            return token;
        }
        return null;
    }

    static logout() {
        cookies.remove('token', { path: '/' });
        cookies.remove('token_expiry', { path: '/' });
        return null;
    }
}
