import config from '../config/config.json';
import AuthService from '../services/AuthService';

function Utf8ArrayToStr(array) {
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = array.length;
    i = 0;
    while(i < len) {
        c = array[i++];
        switch(c >> 4)
        {
            case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
            // 0xxxxxxx
            out += String.fromCharCode(c);
            break;
            case 12: case 13:
            // 110x xxxx   10xx xxxx
            char2 = array[i++];
            out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
            break;
            case 14:
                // 1110 xxxx  10xx xxxx  10xx xxxx
                char2 = array[i++];
                char3 = array[i++];
                out += String.fromCharCode(((c & 0x0F) << 12) |
                    ((char2 & 0x3F) << 6) |
                    ((char3 & 0x3F) << 0));
                break;
        }
    }

    return out;
}

async function asyncPost(url, params, token) {
  const formData  = new FormData();
  Object.keys(params).forEach((param) => {
    if (params[param] !== undefined && params[param] !== null) {
      if (typeof params[param] == 'string') {
        formData.append(param, params[param]);
      } else if(typeof params[param] == 'object') {
        formData.append(param, JSON.stringify(params[param]));
      } else {
        formData.append(param, params[param]);
      }
    }
  });

  let options = {
    method: "POST",
    headers: {
      'Accept': 'application/json'
    },
    body: formData
  }
  if (token) {
    options.headers = {...options.headers,
      'Authorization': 'Bearer '+token
    }
  }
  let response = await fetch(url, options);
  if (!response.ok) { throw response; }
  return await response.json();
}

async function asyncGet(url, params, token) {
  var urlObject = new URL(url);
  urlObject.search = new URLSearchParams(params).toString();
  let options = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }
  if (token) {
    options.headers = {...options.headers,
      'Authorization': 'Bearer '+token
    }
  }
  let response = await fetch(url+urlObject.search, options);
  if (!response.ok) { throw response; }
  return await response.json();
}

async function asyncDelete(url, params, token) {
  let options = {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(params)
  }
  if (token) {
    options.headers = {...options.headers,
      'Authorization': 'Bearer '+token
    }
  }
  let response = await fetch(url, options);
  if (!response.ok) { throw response; }
  return await response.json();
}

async function asyncPut(url, params, token) {
  const formData = new FormData();
  Object.keys(params).forEach((param) => {
    if (params[param] !== undefined) {
      if (typeof params[param] == 'string') {
        formData.append(param, params[param]);
      } else if (typeof params[param] == 'object') {
        formData.append(param, JSON.stringify(params[param]));
      } else {
        formData.append(param, params[param]);
      }
    }
  });

  let options = {
    method: "PUT",
    headers: {
      'Accept': 'application/json'
    },
    body: formData
  }
  if (token) {
    options.headers = {...options.headers,
      'Authorization': 'Bearer '+token
    }
  }
  let response = await fetch(url, options);
  if (!response.ok) { throw response; }
  return await response.json();
}

async function call(method = 'GET', url, rawParams, tokenRequired = true, token = null) {
    const params = Object.keys(rawParams)
        .filter(key => key !== 'token')
        .reduce((obj, key) => {
            obj[key] = rawParams[key];
            return obj;
        }, {});
  if (!token) {
    token = null;
    if (!rawParams.token) {
      if (tokenRequired) {
        return {
          error: 'Unauthenticated'
        };
      }
    } else {
      token = rawParams.token;
    }
  }

  switch (method) {
    case "GET":
      return await asyncGet(url, params, token).then(data => {
        return data;
      }).catch(e => {
        return({
          error: 'Server error',
          info: e
        });
      });
    case "POST":
      return await asyncPost(url, params, token).then(data => {
        return data;
      }).catch(async e => {
          const body = e.body.getReader().read().then(({ done, value }) => {
              return Utf8ArrayToStr(value);
          });
          if (await body) {
              return({
                  error: 'Serverside validation error',
                  info: JSON.parse(await body)
              });
          } else {
              return({
                  error: 'Server error',
              });
          }
      });
    case "PUT":
      return await asyncPut(url, params, token).then(data => {
        return data;
      }).catch(e => {
        return({
          error: 'Server error',
          info: e
        });
      });
    case "DELETE":
      return await asyncDelete(url, params, token).then(data => {
        return data;
      }).catch(e => {
        return({
          error: 'Server error',
          info: e
        });
      });
    default:
      return await asyncGet(url, params, token).then(data => {
        return data;
      }).catch(e => {
        return({
          error: 'Server error',
          info: e
        });
      });
  }
}

const admin_api_url = process.env.NODE_ENV == 'development' ? config.admin_api_url_test : config.admin_api_url_live;
const api_url = process.env.NODE_ENV == 'development' ? config.api_url_test : config.api_url_live;

export default class api {
  
  // Auth
  static async login(params) {                      return await call('POST',   api_url+'login', params, false); }
  static async register(params) {                   return await call('POST',   api_url+'signup', params, false); }
  static async confirmEmail(params) {               return await call('POST',   api_url+'confirm-email', params, false); }
  static async sendResetEmail(params) {             return await call('POST',   api_url+'send-reset-password-email', params, false); }
  static async checkPasswordResetToken(params) {    return await call('POST',   api_url+'check-reset-password-token', params, false); }
  static async updatePasswordInResetFlow(params) {  return await call('POST',   api_url+'update-password', params, false); }
  
  // Public pages
  static async getIndex(params) {                   return await call('GET',    api_url+`index`, params, false); }
  static async getProducts(params) {                return await call('GET',    api_url+`products`, params, false); }
  static async getProduct(params, productId) {      return await call('GET',    api_url+`product/`+productId, params, false); }
  static async getMetaInformation(params) {         return await call('GET',    api_url+`meta`, params, false); }
  static async getFaqs(params) {                    return await call('GET',    api_url+`faqs`, params, false); }
  static async getTncs(params) {                    return await call('GET',    api_url+`tncs`, params, false); }
  static async getAbout(params) {                   return await call('GET',    api_url+`about`, params, false); }
  static async getPrivacyPolicy(params) {           return await call('GET',    api_url+`privacy-policy`, params, false); }

  // Cart
  static async addProduct(params, productId) {      return await call('POST',   api_url+'user/add-to-cart/'+productId, params, true); }
  static async removeProduct(params, productId) {   return await call('DELETE',api_url+'user/remove-from-cart/'+productId, params, true); }
  static async emptyCart(params) {                  return await call('POST',api_url+'user/empty-cart', params, true); }
  static async checkAvailability(params, token) {   return await call('POST',   api_url+'check-items-availability', params, false, token); }
  static async checkFrozenProduct(params, token) {  return await call('POST',   api_url+'check-for-frozen-products', params, false, token); }
  
  // Favourites
  static async getFavourites(params, token) {             return await call('POST',   api_url+'user/favourites', params, true, token); }
  static async addToFavourites(params, productId) {       return await call('POST',   api_url+'user/favourite/'+productId, params, true); }
  static async removeFromFavourites(params, productId) {  return await call('DELETE',api_url+'user/favourite/'+productId, params, true); }
  static async emptyFavourites(params) {                  return await call('POST',api_url+'user/empty-favourites', params, true); }
  
  // Addresses
  static async getAddresses(params) {               return await call('GET',    api_url+`user/addresses`, params, true); }
  static async addAddress(params, token) {          return await call('POST',   api_url+`user/address`, params, true, token); }
  static async updateAddress(params, addressId, token) {   return await call('PUT',    api_url+`user/address/`+addressId, params, true, token); }
  static async deleteAddress(params, addressId) {   return await call('DELETE',    api_url+`user/address/`+addressId, params, true); }

  // User Info
  static async updateAccountDetails(params, token) {       return await call('POST',    api_url+`user/account-details`, params, true, token); }
  static async getOrders(params, token) {           return await call('GET',    api_url+`user/orders`, params, true, token); }
  
  // Payments
  static async initialise(params, token) {          return await call('POST',   api_url+`payment/init`, params, false, token); }
  static async calculate(params, token) {           return await call('POST',   api_url+`payment/calculate`, params, false, token); }
  static async confirm(params, token) {             return await call('POST',   api_url+`payment/confirm`, params, false, token); }
  static async checkVoucherCode(params, token) {    return await call('POST',   api_url+`payment/check-voucher-code`, params, false, token); }
  
  // Get Delivery Cost
  static async getDeliveryCost(params, token) {     return await call('POST',    api_url+`calculate-delivery`, params, false, token); }
  
  // Others
  static async sendMessage(params) {                return await call('POST',    api_url+`message`, params, false); }
}
