import {SET_ADDRESSES} from '../actions/addressActions';

const initState = [];

const addressReducer = (state = initState, action) => {
  if (action.type === SET_ADDRESSES) {
    return action.payload;
  }
  
  return state;
}

export default addressReducer;