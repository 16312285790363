export const SET_BANNERS = "SET_BANNERS";
export const SET_NEW_PRODUCTS = "SET_NEW_PRODUCTS";
export const SET_MOST_POPULAR = "SET_MOST_POPULAR";
export const SET_SALE_ITEMS = "SET_SALE_ITEMS";

export const setBanners = (data) => {
    return dispatch => {
        dispatch({
            type: SET_BANNERS,
            payload: data
        });
    }
};

export const setNewProducts = (data) => {
    return dispatch => {
        dispatch({
            type: SET_NEW_PRODUCTS,
            payload: data
        });
    }
};

export const setMostPopular = (data) => {
    return dispatch => {
        dispatch({
            type: SET_MOST_POPULAR,
            payload: data
        });
    }
};

export const setSaleItems = (data) => {
    return dispatch => {
        dispatch({
            type: SET_SALE_ITEMS,
            payload: data
        });
    }
};
