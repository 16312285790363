import { SAVE_ATTRIBUTES } from "../actions/productAttributesActions";

const initState = []

const productAttributesReducer = (state = initState, action) => {
  if (action.type === SAVE_ATTRIBUTES) {
    return action.payload;
  }
  return state;
}

export default productAttributesReducer;