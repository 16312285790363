import { SET_BANNERS,
         SET_NEW_PRODUCTS,
         SET_SALE_ITEMS,
         SET_MOST_POPULAR }
         from '../actions/frontPageActions';

const initState = {
    banners: [],
    new_products: [],
    most_popular: [],
    sale_items: []
}

const frontPageReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_BANNERS:
            return {
                ...state,
                banners: action.payload
            }
        case SET_NEW_PRODUCTS:
            return {
                ...state,
                new_products: action.payload
            }
        case SET_SALE_ITEMS:
            return {
                ...state,
                sale_items: action.payload
            }
        case SET_MOST_POPULAR:
            return {
                ...state,
                most_popular: action.payload
            }
    }
    return state;
}

export default frontPageReducer;
