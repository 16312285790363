import api from "../../api/api";

export const SAVE_LOGIN_DETAILS = "SAVE_LOGIN_DETAILS";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_USER_INFO = "SET_USER_INFO";

const saveUserDetailsSuccess = userDetails => ({
  type: SAVE_LOGIN_DETAILS,
  payload: userDetails
});

const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: null
});

// Update account info
export const updateUserInfo = (addToast, data, token) => {
  if (addToast) {
    addToast("Account Information Updated", {appearance: "success", autoDismiss: true});
  }
  return (dispatch) => {
    api.updateAccountDetails(data, token)
      .then(result => dispatch(setUserInfo(result.data)));
  }
}

// Update account info
export const setUserInfo = (data) => {
  return dispatch => {
    dispatch({
      type: SET_USER_INFO,
      payload: data
    })
  }
}

// saveUserDetails
export const saveUserDetails = userDetails => {
  return dispatch => {
    dispatch(saveUserDetailsSuccess(userDetails));
  };
};

// Logout
export const logout = () => {
  return dispatch => {
    dispatch(logoutUser());
  };
};