import api from "../../api/api";

export const SET_ADDRESSES = "SET_ADDRESSES";

// Set Addresses
export const setAddresses = (addresses) => {
    return dispatch => {
        dispatch({
            type: SET_ADDRESSES,
            payload: addresses
        });
    }
}

// Fetch Addresses
export const getAddresses = (token) => {
    return (dispatch) => {
        api.getAddresses({ token: token })
            .then(result => dispatch(setAddresses(result.data)));
    }
}

// Add new address
export const addAddress = (addToast, token, address) => {
    if (addToast) {
        addToast("Address Saved", { appearance: "success", autoDismiss: true });
    }
    return (dispatch) => {
        api.addAddress(address, token)
            .then(result => dispatch(setAddresses(result.data)));
    }
}

// Delete an address
export const deleteAddress = (addToast, token, addressId) => {
    if (addToast) {
        addToast("Address Deleted", { appearance: "success", autoDismiss: true });
    }
    return (dispatch) => {
        api.deleteAddress({ token: token }, addressId)
            .then(result => dispatch(setAddresses(result.data)));
    }
}

// Update an address
export const updateAddress = (addToast, token, address) => {
    if (addToast) {
        addToast("Address Updated", { appearance: "success", autoDismiss: true });
    }
    console.log('updating address')
    return (dispatch) => {
        api.updateAddress(address, address.id, token)
            .then(result => {
                if (result.data !== undefined) {
                    dispatch(setAddresses(result.data))
                }
            });
    }
}
