import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../../helpers/product";
import multilanguage from "redux-multilanguage/hoc/multilanguage";

const MenuCart = ({ cartData, strings, deleteFromCart, currentLanguageCode, userData }) => {
  let cartTotalPrice = 0;
  const { addToast } = useToasts();
  const currentWeight = cartData.reduce((t, n) => {
    return t + (parseInt(n.weight) * n.amount)
  }, 0);
  
  return (
    <div className="shopping-cart-content">
      {cartData && cartData.length > 0 ? (
        <Fragment>
          <p>{strings.cart.total_weight}: {(currentWeight/1000).toFixed(2)}kg</p>
          <ul style={{maxHeight:"30vh"}}>
            {cartData.map((single, key) => {
              const discountedPrice = getDiscountPrice(
                single.price,
                single.discount_amount
              );
              const finalProductPrice = single.price;
              const finalDiscountedPrice = discountedPrice;

              single.discount !== false
                ? (cartTotalPrice += single.discounted_price * single.amount)
                : (cartTotalPrice += single.price * single.amount);

              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link to={"/product/" + single.id}>
                      <img
                        alt=""
                        src={single.images[0]}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={"/product/" + single.id}
                      >
                        {" "}
                        {currentLanguageCode === 'hu' ? single.name.hun : single.name.eng}{" "}
                      </Link>
                    </h4>
                    <h6>{strings.cart.qty}: {single.amount}</h6>
                    <span>
                      £{single.discount !== false
                        ? parseFloat(single.discounted_price).toFixed(2)
                        : parseFloat(single.price).toFixed(2)}
                    </span>
                    {single.selectedProductColor &&
                    single.selectedProductSize ? (
                      <div className="cart-item-variation">
                        <span>Color: {single.selectedProductColor}</span>
                        <span>Size: {single.selectedProductSize}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => {
                      deleteFromCart(strings.cart.removed_from_cart, addToast, userData ? userData.access_token : null, single, single.selected_attributes);
                    }}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              {strings.cart.total} :{" "}
              <span className="shop-total">
                £{cartTotalPrice.toFixed(2)}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={"/cart"}>
              {strings.cart.view_cart}
            </Link>
            <Link
              className="default-btn"
              to={"/checkout"}
            >
              {strings.cart.checkout}
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">{strings.cart.no_items}</p>
      )}
    </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  currency: PropTypes.object,
  deleteFromCart: PropTypes.func
};

export default multilanguage(MenuCart);
