import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import multilanguage from "redux-multilanguage/hoc/multilanguage";

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass, strings }) => {
  return (
    <div
      className={`copyright ${spaceBottomClass ? spaceBottomClass : ""} ${
        colorClass ? colorClass : ""
      }`}
    >
      <div className="footer-logo">
        <Link to={"/"}>
          <img alt="" src={footerLogo} width="100%" />
        </Link>
      </div>
      <p>
        © 2020{" "}
        <a href="https://magyarshop.co.uk" rel="noopener noreferrer" target="_blank">
          Magyarshop
        </a>
        .<br /> {strings.footer.all_rights_reserved}
      </p>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string
};

export default multilanguage(FooterCopyright);
