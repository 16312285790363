import PropTypes from "prop-types";
import React from "react";
import { multilanguage } from "redux-multilanguage";
import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";
import {useSelector} from "react-redux";

const HeaderTop = ({
  currentLanguageCode,
  strings,
  dispatch,
  borderStyle
}) => {
  const minimumOrder = useSelector((state) => state.appData.global_settings?.minimum_order)

  return (
    <div
      className={`header-top-wap ${
        borderStyle === "fluid-border" ? "border-bottom" : ""
      }`}
    >
      <LanguageCurrencyChanger
        currentLanguageCode={currentLanguageCode}
        dispatch={dispatch}
      />
      <div className="header-offer">
        <p>
            {strings.header.message} £{JSON.stringify(minimumOrder)}
        </p>
      </div>
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
  setCurrency: PropTypes.func,
  currency: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  dispatch: PropTypes.func
};

export default multilanguage(HeaderTop);
