import {SET_ANNOUNCEMENTS} from '../actions/announcementsActions';

const initState = [];

const announcementsReducer = (state = initState, action) => {
  if (action.type === SET_ANNOUNCEMENTS) {
    return action.payload
  }
  
  return state;
}

export default announcementsReducer;