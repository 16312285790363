import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import MetaTags from "react-meta-tags";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import Layout from "../../layouts/Layout";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import multilanguage from "redux-multilanguage/hoc/multilanguage";
import {Validate, ValidationErrorBox} from "../../components/partials/Validator";
import api from "../../api/api";
import {connect} from "react-redux";
import SectionTitleWithText from "../../components/section-title/SectionTitleWithText";

const validationRules = {
  'password': 'required|min:5|contains:uppercase|contains:lowercase|contains:number'
}

const PasswordRecovery = ({location, strings, token}) => {
  const {pathname} = location;
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [validationResult, setValidationResult] = useState([]);
  const [status, setStatus] = useState('initial');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [password, setPassword] = useState('');
  
  const handleSubmit = async (e) =>{
    let result = Validate(validationRules, {
      'password': password
    });
    if (password !== passwordConfirm) {
      result = [...result, {
        error: 'password_not_matching',
        field: 'password',
        validation_value: password
      }]
    }
    if (result.length === 0) {
      let params = {
        password_reset_token: token,
        password: password,
      }
      const result = await api.updatePasswordInResetFlow(params);
      if (await result) {
        if (result.message) {
          if (result.message === "success") {
            setStatus('submitted');
          }
          if (result.message === "Unable to handle this request.") {
            switch (result.error) {
              case 'Invalid token.':
                setStatus('checked-fail-invalid');
                break;
              case 'Token expired.':
                setStatus('checked-fail-expired');
                break;
              case 'User not found.':
                setStatus('checked-fail-invalid');
                break;
            }
          }
        }
        setSubmitted(true);
      }
    } else {
      setValidationResult(result);
    }
  }
  
  const checkToken = async (e) => {
    const result = await api.checkPasswordResetToken({password_reset_token: token});
    if (await result) {
      if (result.message) {
        if (result.message === "success") {
          setStatus('checked-success');
        } else {
          console.log(result)
          if (result.error) {
            if (result.error === "Invalid token.") {
              setStatus('checked-fail-invalid');
            }
            if (result.error === "Token expired.") {
              setStatus('checked-fail-expired');
            }
          }
        }
      }
    } else {
      setValidationResult(result);
    }
  }
  
  useEffect(() => {
    checkToken();
  }, [])
  
  const renderContent = () => {
    switch (status) {
      case 'initial':
        return (
          <SectionTitleWithText
            spaceTopClass="pt-100"
            spaceBottomClass="pb-95"
            mainTitle={strings['password_recovery_page']['checking_title']}
            text={strings['password_recovery_page']['checking_description']}
          />
        )
        break;
      case 'checked-success':
        return (
          <>
            <label className="ml-10 mt-10">{strings['auth_pages']['enter_your_new_password']}</label>
            <input
              type="password"
              name="user-password"
              placeholder={strings['fields']['password']}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <label className="ml-10 mt-10">{strings['auth_pages']['confirm_your_new_password']}</label>
            <input
              type="password"
              name="user-password"
              placeholder={strings['fields']['password_confirmation']}
              value={passwordConfirm}
              onChange={e => setPasswordConfirm(e.target.value)}
            />
            <div className={'mt-3'} style={{textAlign: 'right'}}>
              <button className='default-btn' onClick={handleSubmit}>
                <span>{strings['buttons']['submit']}</span>
              </button>
            </div>
          </>
        )
        break;
      case 'checked-fail-invalid':
        return (
          <SectionTitleWithText
            spaceTopClass="pt-100"
            spaceBottomClass="pb-95"
            mainTitle={strings['password_recovery_page']['error_title']}
            text={strings['password_recovery_page']['error_details_invalid']}
          />
        )
        break;
      case 'checked-fail-expired':
        return (
          <SectionTitleWithText
            spaceTopClass="pt-100"
            spaceBottomClass="pb-95"
            mainTitle={strings['password_recovery_page']['error_title']}
            text={strings['password_recovery_page']['error_details_expired']}
          />
        )
        break;
      case 'submitted':
        return (
          <SectionTitleWithText
            spaceTopClass="pt-100"
            spaceBottomClass="pb-95"
            mainTitle={strings['password_recovery_page']['success_title']}
            text={strings['password_recovery_page']['success_details']}
          />
        )
        break;
    }
  }
  
  return (
    <Fragment>
      <MetaTags>
        <title>Magyarshop | Forgotten Password</title>
        <meta
          name="description"
          content="Forgotten Password"
        />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>{strings['page_labels']['home']}</BreadcrumbsItem>
      <BreadcrumbsItem to={pathname}>
        {strings['page_labels']['password_reminder']}
      </BreadcrumbsItem>
      <Layout headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb/>
        
        <div className="col-lg-7 col-md-12 ml-auto mr-auto">
          <div className="container mt-3">
            <ValidationErrorBox validationResult={validationResult}/>
            {renderContent()}
          </div>
        </div>
        <br/>
      </Layout>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    token: ownProps.match.params.token
  };
};

export default connect(mapStateToProps, null)(multilanguage(PasswordRecovery));
