import { SAVE_LOGIN_DETAILS } from "../actions/userActions";
import { LOGOUT_USER } from "../actions/userActions";
import { SET_USER_INFO } from "../actions/userActions";

const initState = {
    userDetails: null
};

const userReducer = (state = initState, action) => {
    if (action.type === SET_USER_INFO) {
        const payload = action.payload
        return {
            ...state,
            userDetails: {
                ...state.userDetails,
                user: payload
            }
        };
    }

    if (action.type === SAVE_LOGIN_DETAILS) {
        return {
            ...state,
            userDetails: action.payload
        };
    }

    if (action.type === LOGOUT_USER) {
        return {
            ...state,
            userDetails: null
        };
    }

    return state;
};

export default userReducer;
