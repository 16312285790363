import {applyMiddleware, createStore} from 'redux';
import rootReducer from "./redux/reducers/rootReducer";
import {load, save} from "redux-localstorage-simple";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

export default store;