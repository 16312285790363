import { SAVE_CATEGORIES } from "../actions/productCategoriesActions";

const initState = {
    categories: []
}

const productCategoriesReducer = (state = initState, action) => {
    if (action.type === SAVE_CATEGORIES) {
        return {
            ...state,
            categories: action.payload
        }

    }
    return state;
}

export default productCategoriesReducer;
