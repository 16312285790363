import React from "react";
import {NavLink} from "react-router-dom";
import {Breadcrumbs} from "react-breadcrumbs-dynamic";
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const Breadcrumb = ({loading}) => {
  return (
    <div>
      <div className="breadcrumb-area pt-35 pb-35 bg-gray-3">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <Breadcrumbs
              separator={<span>/</span>}
              item={NavLink}
              finalItem={"span"}
            />
          </div>
        </div>
      </div>
      {loading ? (<LinearProgress color="secondary"/>) : (<></>)}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.appData.loading_in_background,
  };
};

export default connect(mapStateToProps, null)(Breadcrumb);
