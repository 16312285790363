import api from "../../api/api";
import {setCartData} from "./cartActions";

export const GUEST_FAVOURITES_UPDATE = "SET_FAVOURITES_GUEST";
export const SET_FAVOURITES = "SET_FAVOURITES";
export const ADD_TO_FAVOURITES = "ADD_TO_FAVOURITES";
export const DELETE_FROM_FAVOURITES = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_FAVOURITES = "DELETE_ALL_FROM_WISHLIST";

// guest favourites actions
export const updateFavouritesGuest = (type = 1, product = null, amount = null) => {
  // 1: add
  // 2: remove
  return dispatch => {
    dispatch({
      type: GUEST_FAVOURITES_UPDATE, payload: {
        type: type,
        product: product
      }
    });
  }
};

// set favourites
export const setFavourites = (favourites) => {
  return dispatch => {
    dispatch({ type: SET_FAVOURITES, payload: favourites });
  };
};

// add to favourites
export const addToFavourites = (toastString, addToast, token, productId) => {
  if (addToast) {
    addToast(toastString, {
      appearance: "success",
      autoDismiss: true
    });
  }
  return (dispatch) => {
    if (token) {
      api.addToFavourites({ token: token }, productId)
      .then(result => dispatch(setFavourites(result.data)));
    } else {
      dispatch(updateFavouritesGuest(1, productId))
    }
  }
};

// delete from favourites
export const deleteFromFavourites = (toastString, addToast, token, productId) => {
    if (addToast) {
      addToast(toastString, {
        appearance: "error",
        autoDismiss: true
      });
    }
  return (dispatch) => {
    if (token) {
      api.removeFromFavourites({token: token}, productId)
      .then(result => dispatch(setFavourites(result.data)));
    } else {
      dispatch(updateFavouritesGuest(2, productId))
    }
  }
};

//delete all from favourites
export const deleteAllFromFavourites = addToast => {
  return dispatch => {
    if (addToast) {
      addToast("Removed All From Favourites", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_FAVOURITES });
  };
};
