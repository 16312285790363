import PropTypes from "prop-types";
import React from "react";
import SubscribeEmail from "./sub-components/SubscribeEmail";
import multilanguage from "redux-multilanguage/hoc/multilanguage";

const FooterNewsletter = ({
  spaceBottomClass,
  spaceLeftClass,
  sideMenu,
  colorClass,
  widgetColorClass,
  strings
}) => {
  return (
    <div
      className={`footer-widget ${spaceBottomClass ? spaceBottomClass : ""} ${
        sideMenu ? "ml-ntv5" : spaceLeftClass ? spaceLeftClass : ""
      } ${widgetColorClass ? widgetColorClass : ""}`}
    >
      <div className="footer-title">
        <h3>{strings.footer.contact}</h3>
      </div>
      <div className={`subscribe-style ${colorClass ? colorClass : ""}`}>
        <div className="footer-list">
        <ul>
          <li><a target={"_blank"} href={"https://www.google.co.uk/maps/place/Bristol/@51.4684055,-2.7307987,11z/data=!3m1!4b1!4m5!3m4!1s0x4871836681b3d861:0x8ee4b22e4b9ad71f!8m2!3d51.454513!4d-2.58791"}>Bristol</a></li>
          <li><a href={"tel:+447903697180"}>+447903697180</a></li>
          <li><a href={"mailto:info@magyarshop.co.uk"}>info@magyarshop.co.uk</a></li>
        </ul>
        </div>
      </div>
    </div>
  );
};

FooterNewsletter.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  colorClass: PropTypes.string,
  widgetColorClass: PropTypes.string
};

export default multilanguage(FooterNewsletter);
