import {
  SET_FAVOURITES,
  ADD_TO_FAVOURITES,
  DELETE_FROM_FAVOURITES,
  DELETE_ALL_FROM_FAVOURITES,
  GUEST_FAVOURITES_UPDATE
} from "../actions/favouritesActions";

const initState = [];

const favouritesReducer = (state = initState, action) => {
  const favouriteItems = state,
    productId = action.payload;
  
  if (action.type === GUEST_FAVOURITES_UPDATE) {
    const productId = action.payload.product;
    const payload = action.payload;
    switch (payload.type) {
      case 1: // Add
        if (state.indexOf(productId) === -1) {
          return [
            ...state,
            productId
          ];
        }
        break;
      case 2: // Remove
        return state.filter(item => item !== productId )
    }
  }
  
  if (action.type === SET_FAVOURITES) {
    return action.payload;
  }
  
  if (action.type === ADD_TO_FAVOURITES) {
    const favouriteItem = favouriteItems.filter(
      item => item.id === productId
    )[0];
    if (favouriteItem === undefined) {
      return [...favouriteItems, productId];
    } else {
      return favouriteItems;
    }
  }

  return favouriteItems;
};

export default favouritesReducer;
