import { SAVE_TAGS } from "../actions/productTagsActions";

const initState = []

const productTagsReducer = (state = initState, action) => {
  if (action.type === SAVE_TAGS) {
    return action.payload
  }
  return state;
}

export default productTagsReducer;
