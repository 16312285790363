import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import favouritesReducer from "./favouritesReducer";
import compareReducer from "./compareReducer";
import userReducer from "./userReducer";
import productCategoryReducer from "./productCategoriesReducer";
import listOptionsReducer from "./listOptionsReducer";
import frontPageReducer from "./frontPageReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import addressReducer from "./addressReducer";
import productTagsReducer from "./productTagsReducer";
import appReducer from "./appReducer";
import announcementsReducer from "./announcementsReducer";
import productAttributesReducer from "./productAttributesReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "hu" }),
  productData: productReducer,
  cartData: cartReducer,
  favouritesData: favouritesReducer,
  compareData: compareReducer,
  userData: userReducer,
  categoriesData: productCategoryReducer,
  attributesData: productAttributesReducer,
  tagsData: productTagsReducer,
  listOptionsData: listOptionsReducer,
  frontPageData: frontPageReducer,
  addressesData: addressReducer,
  announcementsData: announcementsReducer,
  appData: appReducer
});

export default rootReducer;
