import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import {deleteFromCart, setCartData} from "../../redux/actions/cartActions";
import {logout} from "../../redux/actions/userActions";
import multilanguage from "redux-multilanguage/hoc/multilanguage";

const IconGroup = ({
                     currency,
                     cartData,
                     deleteFromCart,
                     iconWhiteClass,
                     userData,
                     logout,
                     strings,
                     setCartData
                   }) => {
  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };
  
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };
  
  const handleLogout = () => {
    logout();
    setCartData([]);
  }
  
  const renderUserDropdown = () => {
    if (userData) {
      return (
        <ul>
          <li>
            <Link to={"/my-account"}>
              {strings['page_labels']['my_account']}
            </Link>
          </li>
          <li>
            <Link to={"/my-orders"}>
              {strings['page_labels']['my_orders']}
            </Link>
          </li>
          <li>
            <a href={'#'} onClick={handleLogout}>{strings['page_labels']['logout']}</a>
          </li>
        </ul>
      );
    } else {
      return (
        <ul>
          <li>
            <Link to={"/login-register"}>
              {strings['page_labels']['login']}
            </Link>
          </li>
          <li>
            <Link to={"/login-register"}>
              {strings['page_labels']['register']}
            </Link>
          </li>
        </ul>
      );
    }
  }
  
  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      {/*<div className="same-style header-search d-none d-lg-block">*/}
      {/*  <button className="search-active" onClick={e => handleClick(e)}>*/}
      {/*    <i className="pe-7s-search"/>*/}
      {/*  </button>*/}
      {/*  <div className="search-content">*/}
      {/*    <form action="#">*/}
      {/*      <input type="text" placeholder="Search"/>*/}
      {/*      <button className="button-search">*/}
      {/*        <i className="pe-7s-search"/>*/}
      {/*      </button>*/}
      {/*    </form>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="same-style account-setting d-lg-block">
        <button
          className="account-setting-active"
          onClick={e => handleClick(e)}
        >
          <i className="pe-7s-user-female"/>
        </button>
        <div className="account-dropdown">
          {renderUserDropdown()}
        </div>
      </div>
      <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={e => handleClick(e)}>
          <i className="fa fa-shopping-cart" aria-hidden="true"></i>
          <span className="count-style">
            {cartData && cartData.length > 0 ? cartData.reduce((acc, item) => ( acc += parseInt(item.amount) ), 0) : 0}
          </span>
        </button>
        {/* menu cart */}
        <MenuCart
          cartData={cartData}
          currency={currency}
          userData={userData}
          deleteFromCart={deleteFromCart}
        />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={"/cart"}>
          <i className="fa fa-shopping-cart" aria-hidden="true"></i>
          <span className="count-style">
            {cartData && cartData.length > 0 ? cartData.reduce((acc, item) => ( acc += parseInt(item.amount) ), 0) : 0}
          </span>
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu"/>
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartData: state.cartData,
    favouritesData: state.favouritesData,
    compareData: state.compareData,
    userData: state.userData.userDetails,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteFromCart: (toastString, addToast, token, product, attributes) => {
      dispatch(deleteFromCart(toastString, addToast, token, product, attributes));
    },
    logout: () => {
      dispatch(logout())
    },
    setCartData: (cartData) => {
      dispatch(setCartData(cartData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(IconGroup));
