export const SAVE_ATTRIBUTES = "SAVE_ATTRIBUTES";

export const saveAttributes = attributes => {
  return dispatch => {
    dispatch({
      type: SAVE_ATTRIBUTES,
      payload: attributes
    });
  };
};
