import {
  DECREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART,
  SET_CART_DATA, GUEST_CART_UPDATE
} from "../actions/cartActions";
var equal = require('deep-equal')

const initState = [];

const cartReducer = (state = initState, action) => {
  const cartItems = state,
    product = action.payload;
  
  if (action.type === GUEST_CART_UPDATE) {
    const payload = action.payload;
    const inCartIndex = payload.type !== 3 ? state.findIndex(item => (item.id == payload.product.id && equal(item.selected_attributes, payload.selected_attributes))) : null;
    switch (payload.type) {
      case 1: // Add
        if (inCartIndex === -1) {
          return [
            ...state,
            {
              ...payload.product,
              amount: payload.amount,
              selected_attributes: payload.selected_attributes
            }
          ];
        } else {
          const currentAmount = state[inCartIndex].amount;
          const available = state[inCartIndex].stock;
          let newState = state;
          if (available >= (currentAmount + payload.amount)) {
            newState[inCartIndex] = {
              ...payload.product,
              amount: payload.amount + currentAmount,
              selected_attributes: payload.selected_attributes
            }
          } else {
            payload.toast(payload.toast_fail_message, {appearance: "warning", autoDismiss: true});
          }
          return [
            ...newState
          ];
        }
        break;
      case 2: // Remove
        return state.filter(item => !(item.id == payload.product.id && item.selected_attributes == payload.selected_attributes) )
        break;
      case 3: // Delete All
        return [];
        break;
    }
  }
  
  if (action.type === SET_CART_DATA) {
    return [
      ...action.payload
    ]
  }
  
  if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          cartItem => cartItem.cartItemId !== product.cartItemId
        );
      return remainingItems(cartItems, product);
    } else {
      return cartItems.map(item =>
        item.cartItemId === product.cartItemId
          ? {...item, quantity: item.quantity - 1}
          : item
      );
    }
  }
  
  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
      cartItems.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
    return remainingItems(cartItems, product);
  }
  
  if (action.type === DELETE_ALL_FROM_CART) {
    return cartItems.filter(item => {
      return false;
    });
  }
  
  return state;
};

export default cartReducer;
