import api from '../../api/api';

export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const SET_CART_DATA = "SET_CART_DATA";
export const CHECK_AVAILABILITIES = "CHECK_AVAILABILITIES";
export const GUEST_CART_UPDATE = "GUEST_CART_UPDATE";

//add to cart
export const updateCartGuest = (type = 1, product = null, amount = null, selectedAttributes, toast = null, toastFailMessage = null) => {
  // 1: add
  // 2: remove
  // 3: Delete All
  return dispatch => {
    dispatch({
      type: GUEST_CART_UPDATE, payload: {
        type: type,
        product: product,
        amount: amount,
        selected_attributes: selectedAttributes,
        toast: toast,
        toast_fail_message: toastFailMessage
      }
    });
  }
};

//add to cart
export const addToCart = (toastString, toastStringFailed, addToast, token, product, amount = 1, attributes = null) => {
  product.attributes = attributes;
  if (addToast) {
    addToast(toastString, {appearance: "success", autoDismiss: true});
  }
  return (dispatch) => {
    if (token) {
      api.addProduct({token: token, amount: amount, selected_attributes: attributes}, product.id)
      .then(result => {
          if (result.message !== 'maximum_reached') {
            dispatch(setCartData(result.data));
          } else {
            if (addToast) {
              addToast(toastStringFailed, {appearance: "warning", autoDismiss: true});
            }
          }
        }
      );
    } else {
      dispatch(updateCartGuest(1, product, amount, attributes, addToast, toastStringFailed))
    }
  }
};

//remove from cart
export const deleteFromCart = (toastString, addToast, token, product, attributes) => {
  product.attributes = attributes;
  if (addToast) {
    addToast(toastString, {appearance: "success", autoDismiss: true});
  }
  return (dispatch) => {
    if (token) {
      api.removeProduct({token: token, selected_attributes: JSON.stringify(attributes)}, product.id)
      .then(result => dispatch(setCartData(result.data)));
    } else {
      dispatch(updateCartGuest(2, product, null, attributes))
    }
  }
};

//delete all from cart
export const deleteAllFromCart = (toastString, addToast, token) => {
  console.log(toastString)
  if (addToast) {
    addToast(toastString, {
      appearance: "error",
      autoDismiss: true
    });
  }
  return (dispatch) => {
    if (token) {
      api.emptyCart({token: token})
      .then(result => dispatch(setCartData([])));
    } else {
      dispatch(updateCartGuest(3))
    }
  }
};

//delete all from cart
export const checkAvailabilities = (toastString, addToast, token) => {
  if (addToast) {
    addToast(toastString, {
      appearance: "warning",
      autoDismiss: true
    });
  }
  return (dispatch) => {
    api.checkAvailability({token: token})
    .then(result => dispatch(setCartData([])));
  }
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  }
};

export const setCartData = (cartData) => {
  return dispatch => {
    dispatch({type: SET_CART_DATA, payload: cartData});
  }
}
