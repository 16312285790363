export const SET_LOADING_STATE = "SET_LOADING_STATE";
export const SET_BACKGROUND_LOADING_STATE = "SET_BACKGROUND_LOADING_STATE";
export const FORCE_REFRESH = "FORCE_REFRESH";
export const ACCEPT_COOKIES = "ACCEPT_COOKIES";
export const SET_GLOBAL_SETTINGS = "SET_GLOBAL_SETTINGS";

export const setLoading = (loading) => {
  return dispatch => {
    dispatch({
      type: SET_LOADING_STATE,
      payload: loading
    });
  }
}

export const setLoadingInTheBackground = (loading) => {
  return dispatch => {
    dispatch({
      type: SET_BACKGROUND_LOADING_STATE,
      payload: loading
    });
  }
}

export const forceRefresh = () => {
  return dispatch => {
    dispatch({
      type: FORCE_REFRESH,
      payload: null
    });
  }
}

export const acceptCookies = () => {
  return dispatch => {
    dispatch({
      type: ACCEPT_COOKIES,
      payload: null
    });
  }
}

export const setGlobalSettings = (settings) => {
  return dispatch => {
    dispatch({
      type: SET_GLOBAL_SETTINGS,
      payload: settings
    });
  }
}
