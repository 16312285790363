import {REPLACE_CATEGORY, ADD_CATEGORY, CLEAR_TAGS} from "../actions/filterActions";
import {REMOVE_CATEGORY} from "../actions/filterActions";
import {SELECT_ALL_CATEGORIES} from "../actions/filterActions";
import {DESELECT_ALL_CATEGORIES} from "../actions/filterActions";
import {ADD_ATTRIBUTE} from "../actions/filterActions";
import {REMOVE_ATTRIBUTE} from "../actions/filterActions";
import {ADD_TAG} from "../actions/filterActions";
import {REMOVE_TAG} from "../actions/filterActions";
import {SET_PAGE} from "../actions/filterActions";
import {SET_RESULTS_PER_PAGE} from "../actions/filterActions";
import {SET_SEARCH} from "../actions/filterActions";
import {SET_SORT_BY} from "../actions/filterActions";
import {SET_TOTAL_PAGES} from "../actions/filterActions";
import {SWITCH_FAVOURITES} from "../actions/filterActions";

const initState = {
    page: 1,
    per_page: 25,
    search: '',
    filterby: '',
    sort_by: 1,
    favourites: false,
    sort_type: 'DESC',
    categories: [],
    attributes: [],
    tags: [],
    total_pages: 1
}

const listOptionsReducer = (state = initState, action) => {
    switch (action.type) {
        case ADD_TAG:
            const newTags = state.tags.indexOf(action.payload) === -1 ? [...state.tags, action.payload] : [...state.tags];
            return {
                ...state,
                page:1,
                tags: newTags
            }
        case REMOVE_TAG:
            let copyTags = [...state.tags];
            const indexTags = copyTags.indexOf(action.payload);
            copyTags.splice(indexTags, 1);
            return {
                ...state,
                page:1,
                tags: copyTags
            }
        case CLEAR_TAGS:
            return {
                ...state,
                page:1,
                tags: []
            }
        case SWITCH_FAVOURITES:
            const fav = state.favourites;
            return {
                ...state,
                favourites: !fav
            }
        case ADD_CATEGORY:
            const newCategories = state.categories.indexOf(action.payload) === -1 ? [...state.categories, action.payload] : [...state.categories];
            return {
                ...state,
                page:1,
                categories: newCategories
            }
        case REMOVE_CATEGORY:
            let copy = [...state.categories];
            const index = copy.indexOf(action.payload);
            copy.splice(index, 1);
            return {
                ...state,
                page:1,
                categories: copy
            }
        case REPLACE_CATEGORY:
            return {
                ...state,
                page:1,
                categories: [action.payload]
            }
        case SELECT_ALL_CATEGORIES:
            let allCategories = [];
            action.payload.forEach(item => {
                allCategories.push(item.id);
                item.children.forEach(child => {
                    allCategories.push(child.id);
                })
            })
            return {
                ...state,
                page:1,
                categories: allCategories
            }
        case DESELECT_ALL_CATEGORIES:
            return {
                ...state,
                page:1,
                categories: []
            }
        case SET_SEARCH:
            return {
                ...state,
                page:1,
                tags: [],
                categories: [],
                search: action.payload
            }
        case SET_TOTAL_PAGES:
            return {
                ...state,
                total_pages: action.payload
            }
        case SET_PAGE:
            return {
                ...state,
                page: action.payload
            }
        case SET_RESULTS_PER_PAGE:
            return {
                ...state,
                page:1,
                per_page: action.payload
            }
        case SET_SORT_BY:
            return {
                ...state,
                sort_by: action.payload
            }
    }
    return state;
}

export default listOptionsReducer;
