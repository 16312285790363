import PropTypes from "prop-types";
import React, {Fragment, useContext, useState} from "react";
import MetaTags from "react-meta-tags";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import Layout from "../../layouts/Layout";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import multilanguage from "redux-multilanguage/hoc/multilanguage";
import {Validate, ValidationErrorBox} from "../../components/partials/Validator";
import api from "../../api/api";
import SectionTitleWithText from "../../components/section-title/SectionTitleWithText";
import {setLoading} from "../../redux/actions/appActtions";
import {connect} from "react-redux";

const validationRules = {
  'email': 'required|email|min:5',
}

const RegistrationSuccessful = ({location, strings, setLoading}) => {
  const {pathname} = location;
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [validationResult, setValidationResult] = useState([]);
  
  const handleSubmit = async (e) => {
    const result = Validate(validationRules, {
      'email': email
    });
    if (result.length === 0) {
      setLoading(true);
      let params = {
        email: email,
      }
      const result = await api.sendResetEmail(params);
      if (await result) {
        setSubmitted(true);
        setLoading(false);
      }
    } else {
      setValidationResult(result);
    }
  }
  
  return (
    <Fragment>
      <MetaTags>
        <title>Magyarshop | Forgotten Password</title>
        <meta
          name="description"
          content="Forgotten Password"
        />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>{strings['page_labels']['home']}</BreadcrumbsItem>
      <BreadcrumbsItem to={pathname}>
        {strings['page_labels']['password_reminder']}
      </BreadcrumbsItem>
      <Layout headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb/>
        
        <div className="col-lg-7 col-md-12 ml-auto mr-auto">
          <div className="container mt-3">
            {submitted == false ? (
              <>
                <ValidationErrorBox validationResult={validationResult}/>
                <label className="ml-10 mt-10">{strings['auth_pages']['enter_your_email']}</label>
                <input
                  name="user-email"
                  placeholder={strings['fields']['email']}
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <div className={'mt-3'} style={{textAlign: 'right'}}>
                  <button className='default-btn' onClick={handleSubmit}>
                    <span>{strings['buttons']['submit']}</span>
                  </button>
                </div>
              </>
            ) : (
              <>
                <SectionTitleWithText
                  spaceTopClass="pt-100"
                  spaceBottomClass="pb-95"
                  mainTitle={strings['auth_pages']['submitted']}
                  text={strings['auth_pages']['sent_if_exists']}
                />
                <div className={'text-center'}>
                  <a href="/">
                    <button className='default-btn'>
                      <span>{strings['page_labels']['home']}</span>
                    </button>
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <br/>
      </Layout>
    </Fragment>
  );
};

RegistrationSuccessful.propTypes = {
  location: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading: (loading) => {
      dispatch(setLoading(loading));
    },
  }
}
export default connect(null, mapDispatchToProps)(multilanguage(RegistrationSuccessful));
