import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import {connect} from "react-redux";

const MobileNavMenu = ({ strings, currentLanguageCode, categories }) => {
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li>
          <Link to={"/"}>
            {strings["home"]}
          </Link>
        </li>

        <li className="menu-item-has-children">
          <Link to={"/shop"}>
            {strings.page_labels.shop}
          </Link>
          <ul className="sub-menu">
            <li className="menu-item-has-children">
              {categories.map(item => (
                <ul key={item.id}>
                  <li className="mega-menu-title">
                    <Link to={"/shop/" + item.id}>
                      {currentLanguageCode === 'hu' ? item.hun_label : item.eng_label}
                    </Link>
                  </li>
                  {/*{item.children.map(child => (*/}
                  {/*  <li key={child.id}>*/}
                  {/*    <div style={{overflow: "auto"}}>*/}
                  {/*      /!*<img style={{float:"left"}} src={child.image} height={"30px"}/>*!/*/}
                  {/*      <Link style={{float: "left", position:"relative"}} to={"/shop/" + child.id}>*/}
                  {/*          <span style={{*/}
                  {/*            backgroundImage: "url('" + child.image + "')",*/}
                  {/*            width: "30px",*/}
                  {/*            height: "30px",*/}
                  {/*            backgroundPosition: "center",*/}
                  {/*            backgroundRepeat: "no-repeat",*/}
                  {/*            backgroundSize: "cover",*/}
                  {/*            display: "inline-block",*/}
                  {/*            borderRadius: "50%",*/}
                  {/*            marginRight: "5px"*/}
                  {/*          }} />*/}
                  {/*        <span style={{*/}
                  {/*          display: "inline-block",*/}
                  {/*          top: "50%",*/}
                  {/*          transform: "translateY(-35%)"*/}
                  {/*        }}>{currentLanguageCode === 'hu' ? child.hun_label : child.eng_label}</span>*/}
                  {/*      </Link>*/}
                  {/*    </div>*/}
                  {/*  </li>*/}
                  {/*))}*/}
                </ul>
              ))}
            </li>
          </ul>
        </li>
        <li>
          <Link to={"/faqs"}>
            {strings["page_labels"]["faq"]}
          </Link>
        </li>
        <li>
          <Link to={"/contact"}>
            {strings["page_labels"]["contact_us"]}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object
};

const mapStateToProps = state => {
  return {
    categories: state.categoriesData.categories,
  }
}

export default connect(mapStateToProps, null)(multilanguage(MobileNavMenu));
