import PropTypes from "prop-types";
import React, {useEffect, useState, Suspense, lazy} from "react";
import ScrollToTop from "./helpers/scroll-top";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import {ToastProvider} from "react-toast-notifications";
import {multilanguage, loadLanguages} from "redux-multilanguage";
import {connect} from "react-redux";
import {BreadcrumbsProvider} from "react-breadcrumbs-dynamic";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {saveUserDetails} from "./redux/actions/userActions";
import {saveCategories} from "./redux/actions/productCategoriesActions"
import {saveTags} from "./redux/actions/productTagsActions"
import api from "./api/api";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import {setFavourites} from "./redux/actions/favouritesActions";
import {setAnnouncements} from "./redux/actions/announcementsActions";
import ForgotPassword from "./pages/auth/ForgotPassword";
import PasswordRecovery from "./pages/auth/PasswordRecovery";
import {setGlobalSettings} from "./redux/actions/appActtions";
import {saveAttributes} from "./redux/actions/productAttributesActions";
import config from "./config/config.json";
import Rollbar from "rollbar";
import {Provider, ErrorBoundary, LEVEL_WARN} from '@rollbar/react';

const rollbarConfig = {
  accessToken: 'e31f72cde99d4039aa463aa49f8961a7',
  environment: 'local',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

// home pages
const Home = lazy(() => import("./pages/home/Home"));

// shop pages
const Shop = lazy(() => import("./pages/shop/Shop"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// auth pages
const RegistrationSuccessful = lazy(() => import("./pages/auth/RegistrationSuccessful"));
const LoginRegister = lazy(() => import("./pages/auth/LoginRegister"));
const EmailConfirmation = lazy(() => import("./pages/auth/CheckEmailConfirmationToken"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/user/MyAccount"));
const MyOrders = lazy(() => import("./pages/user/MyOrders"));
const Faqs = lazy(() => import("./pages/other/FAQs"));
const Tncs = lazy(() => import("./pages/other/Tncs"));
const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const stripePromise = process.env.NODE_ENV == 'development' ? loadStripe(config.stripe_publishable_key_test) : loadStripe(config.stripe_publishable_key_live);

const App = (props) => {
  const [loaded, setLoaded] = useState(false)
  const classes = useStyles();

  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          hu: require("./translations/magyar.json"),
        }
      })
    );
  });

  // Load categories
  useEffect(() => {
    // const rb = new Rollbar({
    //   accessToken: 'e31f72cde99d4039aa463aa49f8961a7',
    //   captureUncaught: true,
    //   captureUnhandledRejections: true,
    // })
    //
    // rb.error('react test log');
    // // throw new Error('react test error');
    // console.log('rollbar error logged')
    async function fetchData() {
      let params = {}
      if (props.userData.userDetails) {
        params.token = props.userData.userDetails.access_token;
      }
      const data = await api.getMetaInformation(params);
      if (await data.data) {
        if (await data.data.favourites) {
          props.setFavourites(data.data.favourites);
        }
        if (await data.data.popups) {
          props.setAnnouncements(data.data.popups);
        }
        if (await data.data) {
          props.setGlobalSettings({
            maximum_order_weight: data.data.maximum_order_weight,
            minimum_order: data.data.minimum_order,
          });
        }
        const categories = await data.data.categories;
        const productTags = await data.data.tags;
        props.saveCategories(await categories);
        props.saveAttributes(await data.data.attributes);
        props.saveTags(await productTags);
        setLoaded(true)
      }
      if (await data.error) {
        console.log(await data.error);
      }
    }

    fetchData();
  }, [])

  return (
    <Provider config={rollbarConfig}>
      {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar */}
      <ErrorBoundary>
        <Elements stripe={stripePromise}>
          <ToastProvider placement="bottom-left">
            <BreadcrumbsProvider>
              <Router>
                <Backdrop className={classes.backdrop} open={props.loading}>
                  <CircularProgress color="inherit"/>
                </Backdrop>
                <ScrollToTop>
                  <Suspense
                    fallback={
                      <div className="Magyarshop-preloader-wrapper">
                        <div className="Magyarshop-preloader">
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    }
                  >
                    <Switch>
                      <Route
                        path={"/home"}
                        component={Home}
                      />

                      {/* Shop pages */}

                      {/* Category preselected */}
                      <Route
                        path={"/shop/:category"}
                        render={(routeProps) => (
                          <Shop {...routeProps} key={routeProps.match.params.category}/>
                        )}
                      />

                      <Route
                        path={"/shop"}
                        component={Shop}
                      />

                      <Route
                        path={"/forgot-password"}
                        component={ForgotPassword}
                      />

                      <Route
                        path={"/faqs"}
                        component={Faqs}
                      />

                      <Route
                        path={"/terms-and-conditions"}
                        component={Tncs}
                      />

                      <Route
                        path={"/privacy-policy"}
                        component={PrivacyPolicy}
                      />

                      <Route
                        path={"/reset-password/:token"}
                        component={PasswordRecovery}
                      />

                      {/* Shop product pages */}
                      <Route
                        path={"/product/:id"}
                        render={(routeProps) => (
                          <Product {...routeProps} key={routeProps.match.params.id}/>
                        )}
                      />
                      <Route
                        path={"/product-tab-left/:id"}
                        component={ProductTabLeft}
                      />
                      <Route
                        path={"/product-tab-right/:id"}
                        component={ProductTabRight}
                      />
                      <Route
                        path={"/product-sticky/:id"}
                        component={ProductSticky}
                      />
                      <Route
                        path={"/product-slider/:id"}
                        component={ProductSlider}
                      />
                      <Route
                        path={"/product-fixed-image/:id"}
                        component={ProductFixedImage}
                      />

                      {/* Auth pages */}
                      <Route
                        path={"/registration-successful"}
                        component={RegistrationSuccessful}
                      />

                      <Route
                        path={"/email-confirm/:token"}
                        component={EmailConfirmation}
                      />

                      {/* Other pages */}
                      <Route
                        path={"/about"}
                        component={About}
                      />
                      <Route
                        path={"/contact"}
                        component={Contact}
                      />
                      <Route
                        path={"/my-account"}
                        component={MyAccount}
                      />
                      <Route
                        path={"/my-orders"}
                        component={MyOrders}
                      />
                      <Route
                        path={"/login-register"}
                        component={LoginRegister}
                      />

                      <Route
                        path={"/cart"}
                        component={Cart}
                      />
                      <Route
                        path={"/checkout"}
                        component={Checkout}
                      />

                      <Route
                        path={"/not-found"}
                        component={NotFound}
                      />
                      <Redirect to="/home"/>
                      <Route exact component={NotFound}/>
                    </Switch>
                  </Suspense>
                </ScrollToTop>
              </Router>
            </BreadcrumbsProvider>
          </ToastProvider>
        </Elements>
      </ErrorBoundary>
    </Provider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

const mapStateToProps = state => {
  return {
    userData: state.userData,
    categoriesData: state.categoriesData,
    loading: state.appData.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveUserDetails: (userDetails) => {
      dispatch(saveUserDetails(userDetails));
    },
    saveCategories: (categories) => {
      dispatch(saveCategories(categories));
    },
    saveAttributes: (attributes) => {
      dispatch(saveAttributes(attributes));
    },
    saveTags: (tags) => {
      dispatch(saveTags(tags));
    },
    setFavourites: (favourites) => {
      dispatch(setFavourites(favourites));
    },
    setAnnouncements: (announcements) => {
      dispatch(setAnnouncements(announcements));
    },
    setGlobalSettings: (settings) => {
      dispatch(setGlobalSettings(settings));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(App));
