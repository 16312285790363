import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import {multilanguage} from "redux-multilanguage";
import {connect} from "react-redux";

const NavMenu = ({strings, currentLanguageCode, menuWhiteClass, sidebarMenu, categories, userData}) => {
  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        <ul>
          <li>
            <Link to={"/"}>
              {strings["home"]}
            </Link>
          </li>
          <li>
            <Link to={"/shop"}>
              {" "}
              {strings.page_labels.shop}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down"/>
              )}
            </Link>
            <ul className="mega-menu">
              <li>
                {categories.map(item => (
                  <ul key={item.id}>
                    <li className="mega-menu-title">
                      <Link to={"/shop/" + item.id}>
                        <span style={{
                          backgroundImage: "url('" + item.image + "')",
                          width: "30px",
                          height: "30px",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          display: "inline-block",
                          borderRadius: "50%",
                          marginRight: "5px"
                        }}/>
                        <span style={{
                          display: "inline-block",
                          top: "50%",
                          transform: "translateY(-35%)"
                        }}>{currentLanguageCode === 'hu' ? item.hun_label : item.eng_label}</span>
                      </Link>
                    </li>
                    {/*{item.children.map(child => (*/}
                    {/*  <li key={child.id}>*/}
                    {/*    <div style={{overflow: "auto"}}>*/}
                    {/*      /!*<img style={{float:"left"}} src={child.image} height={"30px"}/>*!/*/}
                    {/*      <Link style={{float: "left", position:"relative"}} to={"/shop/" + child.id}>*/}
                    {/*        <span style={{*/}
                    {/*          backgroundImage: "url('" + child.image + "')",*/}
                    {/*          width: "30px",*/}
                    {/*          height: "30px",*/}
                    {/*          backgroundPosition: "center",*/}
                    {/*          backgroundRepeat: "no-repeat",*/}
                    {/*          backgroundSize: "cover",*/}
                    {/*          display: "inline-block",*/}
                    {/*          borderRadius: "50%",*/}
                    {/*          marginRight: "5px"*/}
                    {/*        }}></span>*/}
                    {/*        <span style={{*/}
                    {/*          display: "inline-block",*/}
                    {/*          top: "50%",*/}
                    {/*          transform: "translateY(-35%)"*/}
                    {/*        }}>{currentLanguageCode === 'hu' ? child.hun_label : child.eng_label}</span>*/}
                    {/*      </Link>*/}
                    {/*    </div>*/}
                    {/*  </li>*/}
                    {/*))}*/}
                  </ul>
                ))}
              </li>
            </ul>
          </li>
          <li>
            <Link to={userData ? "/my-account" : "/login-register"}>
              {strings.page_labels.my_account}
            </Link>
          </li>
          <li>
            <Link to={"/faqs"}>
              {strings["page_labels"]["faq"]}
            </Link>
          </li>
          <li>
            <Link to={"/contact"}>
              {strings["page_labels"]["contact_us"]}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object
};

const mapStateToProps = state => {
  return {
    categories: state.categoriesData.categories,
    userData: state.userData.userDetails,
  }
}

export default connect(mapStateToProps, null)(multilanguage(NavMenu));
